<template>
  <el-dialog
    :visible.sync="currentShowFlag"
    top="10px"
    append-to-body
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @open="handleDialogOpen"
    @closed="handleDialogClosed"
  >
    <div
      slot="title"
      class="detail-dialog__header"
    >
      {{ $t('system_office.agencyDetail') }}
    </div>
    <div>
      <simple-form
        ref="form"
        v-model="formModel"
        label-width="110px"
        :form-field="formField"
        :grid="{xs: 24, sm: 12, md: 8}"
        :view-flag="viewFlag"
      >
        <template slot="area">
          <el-cascader
            v-if="!viewFlag"
            v-model="area"
            style="width:100%;"
            :props="{ value: 'id', label: 'name'}"
            :options="areaData"
            check-strictly
            @change="handleAreaChange"
          />
          <div
            v-else
            style="font-size:12px;border-bottom:1px solid #dcdfe6;width:100%;"
          >
            {{ (formModel.province && formModel.province.name) + '/' + (formModel.city && formModel.city.name) }}
          </div>
        </template>
        <template slot="areaEn">
          <el-cascader
            v-if="!viewFlag"
            v-model="area"
            style="width:100%;"
            :disabled="true"
            :props="{ value: 'id', label: 'nameEn'}"
            :options="areaData"
            check-strictly
            @change="handleAreaChange"
          />
          <div
            v-else
            style="font-size:12px;border-bottom:1px solid #dcdfe6;width:100%;"
          >
            {{ (formModel.province && formModel.province.nameEn) + '/' + (formModel.city && formModel.city.nameEn) }}
          </div>
        </template>
      </simple-form>
    </div>
    <div slot="footer">
      <el-button @click="handleDialogClose">
        {{ viewFlag ? $t('operation.close') : $t('operation.cancel') }}
      </el-button>
      <el-button
        v-if="!viewFlag"
        type="primary"
        @click="handleDataSubmit"
      >
        {{ $t('operation.submit') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import Axios from 'axios'
const BASEURL = {
  get: '/system/office/get',
  save: '/system/office/save',
  update: '/system/office/update',
  office: '/jlr/mt/group/list',
  group: '/system/office/list'
}
export default {
  name: 'SystemAgencyDetail',
  props: {
    showFlag: { type: Boolean, default: false },
    viewFlag: { type: Boolean },
    detailData: { type: Object }
  },
  data () {
    return {
      area: [],
      formModel: { parent: {} },
      tableData: [],
      areaData: [],
      groupList: [],
      groupLoading: false
    }
  },
  inject: ['handleDataSearch'],
  computed: {
    currentShowFlag: {
      get () { return this.showFlag },
      set (val) { this.$emit('update:showFlag', val) }
    },
    formField () {
      return [
        {
          prop: 'name',
          label: this.$t('system_office.name'),
          type: 'Input',
          col: { xs: 12, sm: 12, md: 12 },
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        {
          prop: 'nameEn',
          label: this.$t('system_office.nameEn'),
          type: 'Input',
          col: { xs: 12, sm: 12, md: 12 },
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        {
          prop: 'level',
          label: this.$t('system_office.level'),
          type: 'Input',
          component: { disabled: true },
          col: { xs: 12, sm: 12, md: 12 }
        },
        // {
        //   prop: 'category',
        //   label: this.$t('system_office.category'),
        //   type: 'Select',
        //   component: { optionList: this.$getDictList('sys_office_category'), disabled: true },
        //   rules: { trigger: 'blur', message: this.$t('validate.notSelect'), required: true }
        // },
        {
          prop: 'sort',
          label: this.$t('system_office.sort'),
          type: 'NumberInput',
          component: { min: 0 },
          col: { xs: 12, sm: 12, md: 12 },
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        {
          prop: 'code',
          label: this.$t('system_office.code'),
          type: 'Input',
          col: { xs: 12, sm: 12, md: 12 },
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        {
          prop: 'dealerType',
          label: this.$t('system_office.dealerType'),
          type: 'Select',
          component: { optionList: this.$getDictList('dealer_type') },
          col: { xs: 12, sm: 12, md: 12 },
          rules: { trigger: 'blur', message: this.$t('validate.notSelect'), required: true }
        },
        {
          prop: 'shortNameCh',
          label: this.$t('system_office.shortName'),
          type: 'Input',
          col: { xs: 12, sm: 12, md: 12 },
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        {
          prop: 'region',
          label: this.$t('system_office.region'),
          type: 'Select',
          component: { optionList: this.$getDictList('sys_office_region') },
          col: { xs: 12, sm: 12, md: 12 },
          rules: { trigger: 'blur', message: this.$t('validate.notSelect'), required: true }
        },
        {
          label: this.$t('system_office.area'),
          slotName: 'area',
          col: { xs: 12, sm: 12, md: 12 },
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        {
          label: this.$t('system_office.areaEn'),
          slotName: 'areaEn',
          col: { xs: 12, sm: 12, md: 12 },
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        {
          prop: 'parent',
          label: this.$t('system_office.group'),
          type: 'SearchableInput',
          col: { xs: 12, sm: 12, md: 12 },
          component: {
            remoteMethod: this.getGroupList,
            loading: this.groupLoading,
            optionList: this.groupList,
            valueKey: 'id'
          },
          event: {
            focus: () => {
              this.getGroupList()
            }
          },
          rules: {
            type: 'object',
            trigger: 'change',
            message: this.$t('validate.notSelect'),
            required: true,
            fields: {
              id: {
                type: 'string',
                message: this.$t('validate.notSelect'),
                required: true
              }
            }
          }
        },
        {
          prop: 'mainStore',
          label: this.$t('system_office.mainStoreCode'),
          type: 'Input',
          col: { xs: 12, sm: 12, md: 12 },
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        {
          prop: 'cityTier',
          label: this.$t('system_office.cityTier'),
          type: 'Select',
          component: { optionList: this.$getDictList('city_tier') },
          col: { xs: 12, sm: 12, md: 12 },
          rules: { trigger: 'blur', message: this.$t('validate.notSelect'), required: true }
        },
        {
          prop: 'salesArea',
          label: this.$t('system_office.salesArea'),
          type: 'Select',
          component: { optionList: this.$getDictList('sales_area'), clearable: true },
          col: { xs: 12, sm: 12, md: 12 },
          rules: { trigger: 'blur', message: this.$t('validate.notSelect'), required: !this.formModel.afterSalesArea }
        },
        {
          prop: 'afterSalesArea',
          label: this.$t('system_office.afterSalesArea'),
          type: 'Select',
          component: { optionList: this.$getDictList('after_sales_area'), clearable: true },
          col: { xs: 12, sm: 12, md: 12 },
          rules: { trigger: 'blur', message: this.$t('validate.notSelect'), required: !this.formModel.salesArea }
        },
        {
          prop: 'facilityFormat',
          label: this.$t('system_office.facilityScale'),
          type: 'Input',
          col: { xs: 12, sm: 12, md: 12 },
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        {
          prop: 'operationSize',
          label: this.$t('system_office.operationSize'),
          type: 'Input',
          col: { xs: 12, sm: 12, md: 12 },
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        // {
        //   prop: 'shopDate',
        //   label: this.$t('system_office.shopDate'),
        //   type: 'Datepicker',
        //   col: { xs: 12, sm: 12, md: 12 },
        //   rules: { trigger: 'blur', message: this.$t('validate.notSelect'), required: true }
        // },
        {
          prop: 'businessDate',
          label: this.$t('system_office.businessDate'),
          type: 'Datepicker',
          col: { xs: 12, sm: 12, md: 12 },
          rules: { trigger: 'blur', message: this.$t('validate.notSelect'), required: true }
        },
        {
          prop: 'showroomAddressCn',
          label: this.$t('system_office.showroomAddressCn'),
          type: 'Input',
          col: { xs: 12, sm: 12, md: 12 }
        },
        {
          prop: 'showroomAddressEn',
          label: this.$t('system_office.showroomAddressEn'),
          type: 'Input',
          col: { xs: 12, sm: 12, md: 12 }
        },
        {
          prop: 'master',
          label: this.$t('system_office.master'),
          type: 'Input',
          col: { xs: 12, sm: 12, md: 12 },
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        // {
        //   prop: 'phone',
        //   label: this.$t('system_office.phone'),
        //   type: 'Input',
        //   col: { xs: 12, sm: 12, md: 12 },
        //   rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        // },
        {
          prop: 'email',
          label: this.$t('system_office.email'),
          type: 'Input',
          col: { xs: 12, sm: 12, md: 12 },
          rules: [
            { trigger: 'blur', message: this.$t('validate.notValid'), type: 'email' },
            { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
          ]
        },
        {
          prop: 'address',
          label: this.$t('system_office.address'),
          type: 'Input',
          col: { xs: 24, sm: 24, md: 24 },
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        {
          prop: 'addressEn',
          labelWidth: '120px',
          label: this.$t('system_office.addressEn'),
          type: 'Input',
          col: { xs: 24, sm: 24, md: 24 },
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        }
        // {
        //   prop: 'officeGroup',
        //   label: this.$t('system_office.agencyGroup'),
        //   type: 'SearchableInput',
        //   component: {
        //     remoteMethod: this.getGroupList,
        //     loading: this.groupLoading,
        //     optionList: this.groupList,
        //     valueKey: 'id'
        //   },
        //   event: {
        //     focus: () => {
        //       this.getGroupList()
        //     }
        //   }
        //   // rules: {
        //   //   type: 'object',
        //   //   trigger: 'change',
        //   //   message: this.$t('validate.notSelect'),
        //   //   required: true,
        //   //   fields: {
        //   //     id: {
        //   //       type: 'string',
        //   //       message: this.$t('validate.notSelect'),
        //   //       required: true
        //   //     }
        //   //   }
        //   // }
        // }
      ]
    }
  },
  methods: {
    handleDialogClose () {
      this.area = []
      this.currentShowFlag = false
    },
    handleAreaChange (val) {
      this.formModel.province = { id: val[0] }
      this.formModel.city = { id: val[1] }
    },
    // getGroupList (name) {
    //   this.groupLoading = true
    //   this.$axios
    //     .post(BASEURL.office, { pageSize: 20, name: name })
    //     .then(resp => {
    //       const respData = resp.data
    //       this.groupList = respData.list.map(item => {
    //         return { key: item.id, value: item, label: item.name }
    //       })
    //     })
    //     .finally(() => {
    //       this.groupLoading = false
    //     })
    // },
    getGroupList (name) {
      this.groupLoading = true
      const params = {
        filter: [{ left: 'category', operate: '=', right: 'sys_office_category_2' }]
      }
      if (name) {
        params.filter.push({
          left: { left: 'name', operate: 'like', right: name },
          operate: 'or',
          right: { left: 'nameEn', operate: 'like', right: name }
        })
      }
      this.$axios
        .post(BASEURL.group, { pageSize: 10, params })
        .then(resp => {
          const respData = resp.data
          this.groupList = respData.list.map(item => {
            return { key: item.id, value: item, label: item.name }
          })
        })
        .finally(() => {
          this.groupLoading = false
        })
    },
    dealTreeData (data) {
      if (!data) return
      for (let i = 0; i < data.length; i++) {
        if (data[i].children && data[i].children.length < 1) {
          data[i].children = undefined
        } else {
          this.dealTreeData(data[i].children)
        }
      }
      return data
    },
    handleDataSubmit () {
      this.$refs.form.$refs.simpleForm.validate(status => {
        if (status) {
          const loadingFlag = this.$loading({ target: this.$el.querySelector('.el-dialog') })
          let submitModel = this.$_.cloneDeep(this.formModel)
          this.$axios
            .post(this.detailData.id ? BASEURL.update : BASEURL.save, submitModel)
            .then(resp => {
              this.currentShowFlag = false
              this.$message({ type: 'success', message: this.$t('tip.saveSuccess') })
              this.handleDataSearch()
            })
            .finally(() => {
              loadingFlag.close()
            })
        }
      })
    },
    handleDialogOpen () {
      this.areaData = this.$store.state.app.sysArea
      this.dealTreeData(this.areaData)
      if (!this.detailData.id) {
        this.formModel.level = 3
        this.formModel.category = 'sys_office_category_3'
        this.$set(this.formModel, 'parent', this.detailData.parent)
        return
      }
      const loadingFlag = this.$loading({ target: this.$el.querySelector('.el-dialog') })
      const requestList = [this.$axios.post(BASEURL.get, { id: this.detailData.id })]
      Axios
        .all(requestList)
        .then(Axios.spread((resp, log) => {
          const respData = resp.data
          this.formModel = respData
          if (respData.province && respData.city) {
            const area = [respData.province.id, respData.city.id]
            this.$set(this, 'area', area)
            if (respData.shopDate) this.$set(this.formModel, 'shopDate', this.$moment(respData.shopDate).format('YYYY-MM-DD'))
            if (respData.businessDate) this.$set(this.formModel, 'businessDate', this.$moment(respData.businessDate).format('YYYY-MM-DD'))
          }
          this.groupList = [
            { key: respData.parent.id, value: respData.parent, label: respData.parent.name }
          ]
          // if (respData.officeGroup) this.initialSelectOptionList(respData.officeGroup)
        }))
        .finally(() => {
          loadingFlag.close()
        })
    },

    // initialSelectOptionList (company) {
    //   this.groupList = [{ key: company.id, value: company, label: company.name }]
    // },

    handleDialogClosed () {
      this.$refs.form.resetFields()
      this.formModel = { parent: {} }
    }
  }
}
</script>
